<template>
  <div class="status-chip" :style="{ backgroundColor: configuration.background }">
    <v-icon small :color="configuration.iconColor">
      {{ configuration.icon }}
    </v-icon>
    <span :style="{ color: configuration.textColor }">{{ configuration.text }}</span>
  </div>
</template>

<script>
import { ENUM_ACTIONS, ENUM_STATUS } from '@/modules/industry/enums'

export default {
  name: 'StatusInTable',
  props: {
    status: {
      type: String,
      required: true,
      validator: value => [ENUM_STATUS.PENDING, ENUM_STATUS.SUCCESS, ENUM_STATUS.MISSING_PRODUCTS, ENUM_STATUS.ERROR].includes(value)
    },
    action: {
      type: String,
      required: true,
      validator: value => [ENUM_ACTIONS.CREATE, ENUM_ACTIONS.DELETE, ENUM_ACTIONS.UPDATE].includes(value)
    }
  },
  data: () => ({
    ENUM_ACTIONS,
    ENUM_STATUS
  }),
  computed: {
    configuration() {
      const key = `${this.action}_${this.status}`
      const baseConfig = {
        background: '',
        iconColor: '',
        icon: '',
        textColor: '',
        text: ''
      }

      switch (this.status) {
        case ENUM_STATUS.PENDING:
          baseConfig.background = '#0063C61A'
          baseConfig.iconColor = '#0063C6'
          baseConfig.icon = 'autorenew'
          baseConfig.textColor = '#004D99'
          break
        case ENUM_STATUS.SUCCESS:
          baseConfig.background = '#0DA7401A'
          baseConfig.iconColor = '#0DA740'
          baseConfig.textColor = '#086A29'
          switch (this.action) {
            case ENUM_ACTIONS.CREATE:
              baseConfig.icon = 'check_circle'
              break
            case ENUM_ACTIONS.DELETE:
              baseConfig.icon = 'delete'
              baseConfig.background = '#5b5b5b1a'
              baseConfig.iconColor = '#5b5b5b'
              baseConfig.textColor = '#4f4f4f'
              break
            case ENUM_ACTIONS.UPDATE:
              baseConfig.icon = 'edit'
              break
            default:
              baseConfig.icon = 'close'
          }
          break
        case ENUM_STATUS.MISSING_PRODUCTS:
          baseConfig.background = '#F2803F1A'
          baseConfig.iconColor = '#F2803F'
          baseConfig.icon = 'check_circle'
          baseConfig.textColor = '#C65718'
          break
        case ENUM_STATUS.ERROR:
          baseConfig.background = '#F47F6833'
          baseConfig.iconColor = '#E03210'
          baseConfig.icon = 'warning'
          baseConfig.textColor = '#B3280D'
          break
        default:
          baseConfig.background = '#FFFFFF1A'
          baseConfig.iconColor = '#FFFFFF'
          baseConfig.textColor = '#000000'
          baseConfig.text = 'Não identificado'
          return baseConfig
      }

      const textMap = {
        [`${ENUM_ACTIONS.CREATE}_${ENUM_STATUS.PENDING}`]: 'Processando criação',
        [`${ENUM_ACTIONS.CREATE}_${ENUM_STATUS.SUCCESS}`]: 'Criado',
        [`${ENUM_ACTIONS.CREATE}_${ENUM_STATUS.MISSING_PRODUCTS}`]: 'Criado c/ prod. falt.',
        [`${ENUM_ACTIONS.CREATE}_${ENUM_STATUS.ERROR}`]: 'Falha na criação',
        [`${ENUM_ACTIONS.DELETE}_${ENUM_STATUS.PENDING}`]: 'Processando remoção',
        [`${ENUM_ACTIONS.DELETE}_${ENUM_STATUS.SUCCESS}`]: 'Removido',
        [`${ENUM_ACTIONS.DELETE}_${ENUM_STATUS.ERROR}`]: 'Falha na remoção',
        [`${ENUM_ACTIONS.UPDATE}_${ENUM_STATUS.PENDING}`]: 'Processando edição',
        [`${ENUM_ACTIONS.UPDATE}_${ENUM_STATUS.SUCCESS}`]: 'Editado',
        [`${ENUM_ACTIONS.UPDATE}_${ENUM_STATUS.MISSING_PRODUCTS}`]: 'Editado c/ prod. falt.',
        [`${ENUM_ACTIONS.UPDATE}_${ENUM_STATUS.ERROR}`]: 'Falha na edição'
      }

      baseConfig.text = textMap[key] || 'Não identificado'
      return baseConfig
    }
  }
}
</script>

<style lang="scss" scoped>
.status-chip {
  border-radius: 4px;
  padding: 2px 6px 2px 4px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
</style>
